<template>
  <b-card title="Thông tin danh mục">
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Tên danh mục"
              label-cols-md="2"
              label-for="h-first-name"
          >
            <b-form-input
                id="h-first-name"
                v-model="categoryData.name"
                placeholder="Quả ngắn ngày"
                :class="{ 'is-invalid' : isInvalid(categoryData.name)}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-1"
            type="submit"
            variant="primary"
        >
          Lưu thông tin
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";

export default {
  name: "CategoryEdit",
  directives: {
    Ripple
  },
  data() {
    return {
      categoryData: {
        name: ''
      },
      currentData: null,
      isValidate: false
    }
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
  },
  async created() {
    let id = router.currentRoute.params.id;
    const response = await this.$http.get(`/categories/${id}`);
    if (response.status === 200) {
      this.categoryData = response.data.data
      this.currentData = Object.assign({}, response.data.data)
    }
    this.$http.get('/search/category?page_id=SP')
        .then(res => {
          this.categories = res.data.data
        })
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    handleSubmit() {
      this.isValidate = true
      if (!this.isInvalid(this.categoryData.name)) {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    async handleRequest() {
      if (this.categoryData.name === this.currentData.name) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Cập nhật thành công👋',
            variant: 'success',
          },
        })
        await this.$router.push('/manage/category/list')
      } else {
        this.categoryData.description = this.categoryData.name
        this.$http.put('/categories/' + this.categoryData.id, this.categoryData)
            .then(response => {
              console.log(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Cập nhật thành công👋',
                  variant: 'success',
                },
              })
              this.$router.push('/manage/category/list')
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Thông báo',
                  icon: 'BellIcon',
                  text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                  variant: 'danger',
                },
              })
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
